
import { lazy } from 'react';


export default class BBSVGEditorDescriptor {



static getDescription() {
    return {
      name: 'BB SVGEditor',
      component: lazy(() =>  import('./BBSVGEditor')),
      label: 'BB SVGEditor',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'Query to fill the svg. use ?uri and ?svg',
        },
       
      ],
    }
  }

}