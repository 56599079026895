import * as React from "react"
import { Check, ChevronsUpDown } from "lucide-react"

import { mergeClasses } from "../../helpers/styling"
import { Button } from "./button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./popover"


export type ComboboxOption = {
  label: string
  value: string
}

export function SearchSelect(props: {
  value: string
  onChange?: (value: string) => void
  options: ComboboxOption[]
  placeholder?: string
  noResultsLabel?: string
  buttonClassName?: string
  contentClassName?: string
}) {
  const [open, setOpen] = React.useState(false)

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={mergeClasses('w-full justify-between !px-2', props.buttonClassName)}
        >
          {props.value
            ? props.options.find((option) => option.value === props.value)?.label
            : (props.placeholder ?? 'Select an option...')}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className={mergeClasses('w-[200px] p-0', props.contentClassName)}
                      style={{ zIndex: 9999, width: 'var(--radix-popper-anchor-width)' }}>
        <Command>
          <CommandInput placeholder={props.placeholder ?? 'Search...'} />
          <CommandList>
            <CommandEmpty>{props.noResultsLabel ?? 'No options found'}</CommandEmpty>
            <CommandGroup>
              {props.options.map((option) => (
                <CommandItem
                  key={option.value}
                  value={option.value}
                  onSelect={(currentValue) => {
                    props.onChange?.(currentValue === props.value ? "" : currentValue)
                    setOpen(false)
                  }}
                >
                  <Check
                    className={mergeClasses(
                      "mr-2 h-4 w-4",
                      props.value === option.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
